import * as React from "react"
import { useEffect, useState } from "react"
import Header from "./Header"
import Footer from "./Footer"
import PiwikPro from "@piwikpro/react-piwik-pro"
/**
 * This component is for the rendering of header, main and footer.
 *
 * @version 1.0.1
 * @visibleName Layout
 * @author [Dylan Lesperance]
 */

function HumanLayout(props) {
  if (typeof window !== "undefined") {
    // or typeof document !== 'undefined'
    PiwikPro.initialize(
      "60fe0567-dca4-40aa-878f-04cc49bf4905",
      "https://human.containers.piwik.pro"
    )
  }

  return (
    //Footer will always stay at the bottom with this styling
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="bg-gray flex-grow">{props.children}</main>
      <Footer />
    </div>
  )
}

export default HumanLayout
