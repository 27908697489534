/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

import { QueryClient, QueryClientProvider } from "react-query"
import React from "react"
import wrapWithProvider from "./src/components/Security/wrap-with-provider"
import Layout from "./src/components/layout"  
import HumanLayout from "./src/components/Styling/HumanLayout"

export const wrapPageElement = ({ element, props }) => {
  const queryClient = new QueryClient()

  return (
    <HumanLayout {...props}>
      <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
    </HumanLayout>
  )
}
export const wrapRootElement = wrapWithProvider
