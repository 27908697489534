exports.components = {
  "component---src-components-my-page-home-tsx": () => import("./../../../src/components/MyPage/Home.tsx" /* webpackChunkName: "component---src-components-my-page-home-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-administrer-giveravtale-tsx": () => import("./../../../src/pages/administrer-giveravtale.tsx" /* webpackChunkName: "component---src-pages-administrer-giveravtale-tsx" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-giveravtale-tsx": () => import("./../../../src/pages/giveravtale.tsx" /* webpackChunkName: "component---src-pages-giveravtale-tsx" */),
  "component---src-pages-hefinn-index-tsx": () => import("./../../../src/pages/hefinn/index.tsx" /* webpackChunkName: "component---src-pages-hefinn-index-tsx" */),
  "component---src-pages-hefinn-medlemskap-i-andre-samfunn-tsx": () => import("./../../../src/pages/hefinn/medlemskap-i-andre-samfunn.tsx" /* webpackChunkName: "component---src-pages-hefinn-medlemskap-i-andre-samfunn-tsx" */),
  "component---src-pages-hefinn-mine-opplysninger-tsx": () => import("./../../../src/pages/hefinn/mine-opplysninger.tsx" /* webpackChunkName: "component---src-pages-hefinn-mine-opplysninger-tsx" */),
  "component---src-pages-henvendelser-tsx": () => import("./../../../src/pages/henvendelser.tsx" /* webpackChunkName: "component---src-pages-henvendelser-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kvittering-avtale-tsx": () => import("./../../../src/pages/kvittering-avtale.tsx" /* webpackChunkName: "component---src-pages-kvittering-avtale-tsx" */),
  "component---src-pages-kvittering-tsx": () => import("./../../../src/pages/kvittering.tsx" /* webpackChunkName: "component---src-pages-kvittering-tsx" */),
  "component---src-pages-lukkede-henvendelser-tsx": () => import("./../../../src/pages/lukkede-henvendelser.tsx" /* webpackChunkName: "component---src-pages-lukkede-henvendelser-tsx" */),
  "component---src-pages-magasininn-index-tsx": () => import("./../../../src/pages/magasininn/index.tsx" /* webpackChunkName: "component---src-pages-magasininn-index-tsx" */),
  "component---src-pages-magasininn-mine-opplysninger-tsx": () => import("./../../../src/pages/magasininn/mine-opplysninger.tsx" /* webpackChunkName: "component---src-pages-magasininn-mine-opplysninger-tsx" */),
  "component---src-pages-ny-henvendelse-tsx": () => import("./../../../src/pages/ny-henvendelse.tsx" /* webpackChunkName: "component---src-pages-ny-henvendelse-tsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-tillitsvalgt-tsx": () => import("./../../../src/pages/tillitsvalgt.tsx" /* webpackChunkName: "component---src-pages-tillitsvalgt-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-velkommen-tsx": () => import("./../../../src/pages/velkommen.tsx" /* webpackChunkName: "component---src-pages-velkommen-tsx" */),
  "component---src-pages-verifisering-js": () => import("./../../../src/pages/verifisering.js" /* webpackChunkName: "component---src-pages-verifisering-js" */)
}

